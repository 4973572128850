import React from 'react';
import logotype from "../images/logotype.svg";
import { cancelJob, createImportJob, startJob, uploadFilesChunk } from "../utils/coreAPI";
import tokenService from "../services/TokenService";
import { getFileSizeText } from "../utils/fileUtils";
import filesDeduplicationService from "../services/FilesDeduplicationService";
import css from './ImportPage.module.css';
import { NewUploadMediaWindow } from '../components/NewUploadMediaWindow';
import { NewUploadMediaState } from '../components/NewUploadMediaState';
import NewMobileApps from '../components/NewMobileApps'
import { NewContactsBox } from "../components/NewContactsBox";
import {handleUploadList, resetUploadList} from "../utils/api";

class ImportPage extends React.Component {
    constructor(props) {
        super(props);

        filesDeduplicationService.clear();
        window.UploadTasksRejectedManually = {}

        this.state = {
            quality: 'HQ',
            jobs: {},
            jobsPollingError: null,
            uploading: false,
            duplicatesCount: 0,
            duplicatesFoundTime: 0,
            warning: null,
            uploadRequest: null
        };
    }

    async pollJobs() {
        // first step is get all jobs
        const { jobs } = this.state;
        let newJobsState = { ...jobs };


        const jobsInProgress = Object.keys(jobs)
            .map(jobKey => jobs[jobKey])
            .filter(job => job.job.status !== 'Canceled' && !job.job.cancelRequested)
            .filter(job => job.job.status !== 'Completed' && job.job.status !== 'Error');

        // in case if no pending jobs
        if (jobsInProgress.length === 0) {
            console.log("NO JOBS IN PROGRESS")
            return;
        }

        let bufObj = {}
        Object.keys(newJobsState).forEach(id => {
            if (jobs[id].job.status !== 'Canceled' && jobs[id].job.status !== 'Error') {
                bufObj[id] = newJobsState[id]
            }
            if(window.sequentialJobs && window.sequentialJobs.includes(id)){
                bufObj[id] = newJobsState[id];
            }
        })
        newJobsState = bufObj

        /*if(!this.state.uploading && !this.state.updatingStatus){
            this.setState({ updatingStatus: true });
            try {
                // let's walk through jobs and update their states
                await Promise.all(
                    jobsInProgress.map(async (job) => {
                        const { id } = job.job;
                        if(id){
                            let response = await getJobStatus(id, false);
                            newJobsState[id].job = response;
                            if (response.status === "Error") {
                                newJobsState[id].job.canceled = true
                                throw new Error(response)
                            }
                        }
                    })
                );
                // remove error description in case if poll success
                this.setState({ jobsPollingError: null });
            } catch (e) {
                // an error occurred case
                this.setState({ jobsPollingError: 'Error while uploading.\nTry later.' });
            }
    
            // all state retrieved, let's set new jobs state
            this.setState({ jobs: newJobsState });
            this.setState({ updatingStatus: false });
        }*/
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkToken();
    }

    componentDidMount() {
        this.checkToken();
        this.setState({
            jobsPollInterval: setInterval(this.pollJobs.bind(this), 5000)
        });
    }

    checkToken() {
        const tokenDataValid = tokenService.tokenDataValid();

        if (!tokenDataValid) {
            window.location.href = '/';
        }
    }

    handleLogOut() {
        tokenService.logout();
        window.location.href = '/';
    }

    getTotalSize(items) {
        let sum = 0;
        items.forEach(item => sum += item.file.size);

        return sum;
    }

    async handleNewFiles(files, acceptedFiles) {
        this.setState({ warning: null })
        this.setState({ jobsPollingError: null });

        if (files.length !== acceptedFiles.length) {
            this.setState({ warning: 'Some of the files you are trying to upload are in an unsupported format.' })
        }

        const maxTotalSize = 1000 * 1000 * 1000 * 100; // 100 GB
        const { jobs } = this.state;
        const allItems = [];
        const items = [];

        // fill all items in order to get rid of duplicates and calc total size
        Object.keys(jobs).forEach(jobKey => {
            const job = jobs[jobKey];
            //consider only non cancelled jobs
            if (job.job.status === 'Canceled' || job.job.cancelRequested) {
                delete jobs[jobKey];
            } else {
                job.items.forEach(item => allItems.push(item));
            }
        });

        this.setState({ jobs: jobs });

        acceptedFiles.forEach(file => {
            // size check
            const sizeAfterAddNewFile = file.size + this.getTotalSize(allItems);
            if (maxTotalSize > sizeAfterAddNewFile) {
                // create a new item and put in both allItems & items collections
                const item = {
                    file,
                    mediaId: null,
                    filename: file.webkitRelativePath || file.name,
                    status: 'ready_for_upload',
                    filesize: getFileSizeText(file.size),
                    uploadingPercent: 0
                };

                allItems.push(item);
                items.push(item);
            } else {
                const bigSizeWarn = 'Some of the files you are trying to upload are big size.';
                if (this.warning === null) {
                    this.setState({ warning: bigSizeWarn });
                } else {
                    this.setState({ warning: `${this.warning} ${bigSizeWarn}` });
                }
                console.log('Too big files');
            }
        });

        //deduplicate items before show them in UI
        const report = filesDeduplicationService.proceedFiltering(items);

        // set duplicates count
        const duplicatesCount = report.duplicatesCount
        this.setState({
            duplicatesCount,
            duplicatesFoundTime: duplicatesCount > 0 ? new Date().getTime() : 0
        });

        try {
            await this.startImport(report.items);
        } catch (e) {
            console.log(e);
            this.setState({ jobsPollingError: 'Error while uploading.\nTry later.' })
        }
    }

    async startImport(items) {
        // no job expected to start
        if (items.length === 0) {
            return;
        }

        window.lastFile = null;
        const jobInstance = await createImportJob(this.state.quality, items);

        // enrich items data with mediaId and writable urls
        const newItems = items.map(item => {
            // console.log(item.file.webkitRelativePath || item.filename);
            // console.log(item.webkitRelativePath);
            // console.log(item.file.path);
            // console.log(item.file.value);
            const media = jobInstance.items.find(mediaItem => mediaItem.filename === (item.file.webkitRelativePath || item.file.name) && mediaItem.size === item.file.size);

            return {
                ...item,
                status: 'uploading',
                mediaId: media.id,
                writableUrl: media.writableUrl,
                uploadUrls: media.uploadUrls,
                uploadId: media.uploadId,
                importId:jobInstance.id
            };
        });

        // update current jobs state object
        let jobs = { ...this.state.jobs };
        let bufObj = {}
        Object.keys(jobs).forEach(id => {
            if (jobs[id].job.status !== 'Completed' && jobs[id].job.status !== 'Error') {
                bufObj[id] = jobs[id]
            }
        })
        jobs = bufObj
        jobs[jobInstance.id] = {
            job: jobInstance,
            items: newItems
        };

        this.setState({ jobs });
        // start uploading files
        this.setState({ uploading: true });

        this.aggregateJobs(jobs)

        this.readFiles(jobs, newItems, jobInstance)
    }

    async readFiles(jobs, newItems, jobInstance) {
        console.log(`CALLED UPLOAD for importJob - ${jobInstance.id}`);
        await this.uploadFiles(jobs, newItems, jobInstance);
    }

    aggregateJobs(jobs){
        if(window.sequentialJobs){
            let ids = window.sequentialJobs;
            let allJobsCompleted = true;
            ids.forEach(jobId => {
                if(jobs && jobs[jobId]){
                    const job = jobs[jobId].job;
    
                    // check job status
                    const jobCompleted = job.status === 'Completed' || job.status === 'Error'
                    allJobsCompleted = allJobsCompleted && jobCompleted
                }
            })
            if(allJobsCompleted)
                window.sequentialJobs = []
        } else {
            window.sequentialJobs = []
        }
        Object.keys(jobs).forEach(id => {
                if (jobs[id].job.status !== 'Completed' && jobs[id].job.status !== 'Error') {
                    if(!window.sequentialJobs.includes(id))
                        window.sequentialJobs.push(id)
                }
            })
    }


    async uploadFiles(jobs, newItems, jobInstance) {
        try {
            await uploadFilesChunk(newItems, (item) => {
                // console.log(item);
            });
            await handleUploadList();
            console.log("AWAIT");
            window.UploadTasksRejectedManually[jobInstance.id] = false
            window.UploadTasks = []
        } catch (e) {
            console.log(`ERROR WHILE UPLOADING for importJob - ${jobInstance.id}`, e)
            if (window.UploadTasksRejectedManually[jobInstance.id]) {
                console.log(`MANUALLY CANCELED importJob - ${jobInstance.id}`)
                window.UploadTasksRejectedManually[jobInstance.id] = false
                window.UploadTasks = []
                jobs[jobInstance.id].job.cancelRequested = true;
                this.setState({ jobs });
                this.setState({ uploading: false });
            } else {
                console.log(`NOT MANUALLY CANCELED importJob - ${jobInstance.id}`, e)
                jobs[jobInstance.id].job.status = 'Error';
                jobs[jobInstance.id].job.cancelRequested = true;
                this.setState({ jobs });
                this.setState({ uploading: false });
                this.setState({ jobsPollingError: 'Error while uploading.\nTry later.' })
            }
            return;
        }

        this.setState({ uploading: false });

        // remember files that sent to upload
        filesDeduplicationService.proceedUpload(jobInstance.id, newItems);

        // after files uploaded - start the job
        await startJob(jobInstance.id);
    }

    async handleCancelJobsClick() {
        resetUploadList();
        this.setState({ uploading: false });

        //cancel all upload xhr requests
        if (window.UploadTasks) {
            window.UploadTasks.forEach(item => {
                if (item) {
                    console.log("Reject xhr operation")
                    item.abort()
                }
            })
            Object.keys(this.state.jobs).forEach(function (key, index) {
                window.UploadTasksRejectedManually[key] = true
            });
        }


        console.log("CANCEL")
        // let's iterate over jobs and cancel them
        try {
            await Promise.all(
                Object
                    .keys(this.state.jobs)
                    .filter(item => !this.state.jobs[item].job.status || this.state.jobs[item].job.status === "Created" || this.state.jobs[item].job.status === "InProgress" || this.state.jobs[item].job.status === "PostProcessing")
                    .map((item) => {
                        console.log(`REMOVE CANCELED FILES for importJob - ${item}`)
                        filesDeduplicationService.removeFiles(item);
                        return cancelJob(item);
                    })
            );
        } catch (e) {
            this.setState({jobsPollingError: 'Error while cancelling.\nTry later.'})
        }

    }

    render() {
        return (

            <main>

                {/* .page - страница на сером фоне
                    .wrap - ограничивает контент по бокам

                    .section - белые плашки-секции
                    .section__wrap - врапер для плашки секции
                */}

                <div className={css.page}>
                    <div className={css.wrap}>


                        <div className={css.section}>
                            <div className={css.section__wrap}>
                                <div className={css.section__headline}>
                                    <img className={css.logo} src={logotype} alt="logo"></img>
                                    <a href="/" className={css.link_exit} onClick={this.handleLogOut.bind(this)} target='_blank' rel="noopener noreferrer"><i style={{ display: "none" }}></i></a>
                                </div>

                                <div className={css.section__columns}>
                                    <NewUploadMediaWindow
                                        handleNewFiles={this.handleNewFiles.bind(this)}
                                    />

                                    <NewUploadMediaState
                                        jobs={this.state.jobs}
                                        uploading={this.state.uploading}
                                        jobsPollingError={this.state.jobsPollingError}
                                        duplicatesCount={this.state.duplicatesCount}
                                        duplicatesFoundTime={this.state.duplicatesFoundTime}
                                        warning={this.state.warning}
                                        handleCancelJobsClick={this.handleCancelJobsClick.bind(this)}
                                    />
                                </div>

                                <div className={css.section__footer}>
                                    <NewMobileApps storeClass={css.store} />

                                    <NewContactsBox contactStyle={css.contact} />
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </main>

        )
    }
}

export default ImportPage;